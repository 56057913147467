// React Required
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
// Create Import File
import './index.scss';
// Home layout
import PortfolioLanding from './home/PortfolioLanding';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import error404 from "./elements/error404";
import AOS from 'aos';

class Root extends Component {
    componentDidMount() {
        AOS.init();
    }
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path="/" component={PortfolioLanding}/>
                    {/*<Route exact path="/yoga" component={Yoga}/>*/}
                    <Route path="/404" component={error404}/>
                    <Route component={error404}/>
                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
// ReactDOM.render(<PortfolioLanding/>, document.getElementById('root'));
serviceWorker.register();