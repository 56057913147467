import React from "react";
import { FaFacebookF , FaLinkedinIn , FaTwitter, FaInstagram } from "react-icons/fa";

let data = [
    {
        images: '01',
        title: 'Alexandre Da Costa',
        designation: 'Je propose un "service clé en main" pour promouvoir la biodiversité et agir pour la protection de l\'environnement en installant des ruches sur site pour les professionnels ou les particuliers.',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://fr.linkedin.com/in/alexandre-da-costa-85b2a696'
            }
        ]
    },
    {
        images: '02',
        title: 'Sandrine Farnetti',
        designation: 'J\'accompagne les femmes entre 40 et 50 ans en pré-ménopause qui se sentent fatiguées, déprimées et démunies face aux symptômes de cette période à retrouver leur énergie et leur joie de vivre pour continuer à jouir pleinement de la vie en toute liberté.',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
        ]
    },
    {
        images: '03',
        title: 'Christelle Frigo',
        designation: 'Yoga et Pilates',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '04',
        title: 'Bill',
        designation: 'Boxe',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaInstagram />,
                url: '#'
            }
        ]
    },
    {
        images: '05',
        title: 'Jane',
        designation: 'Sophrologie',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
        ]
    },
    {
        images: '06',
        title: 'Maverick',
        designation: 'Sr. Graphic Designer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '07',
        title: 'Santiago',
        designation: 'Sr. Graphic Designer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '08',
        title: 'Robert Karle',
        designation: 'Sr. Graphic Designer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '09',
        title: 'Jaxson Jace',
        designation: 'Sr. Graphic Designer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '10',
        title: 'Adam Smith',
        designation: 'Sr. Graphic Designer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },

];

export default data;