import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row" data-aos="fade-up" data-aos-delay="100">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="author-info">
                                                <h6><span>Christelle </span> - Yoga et Pilates</h6>
                                            </div>
                                            <p className="description">
                                                Lorem ipsum dolor sit amet, conse ctetur adipiscing elit Lorem ipsum dolor sit amet, conse ctetur adipiscing elit<br />
                                                Lorem ipsum dolor sit amet, conse ctetur adipiscing elit Lorem ipsum dolor sit amet, conse ctetur adipiscing elit
                                            </p>
                                            {/*<div className="inner">*/}
                                            {/*    <p>Jogn Due The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections Bonorum et Malorum original.</p>*/}
                                            {/*</div>*/}
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            {/*<div className="inner">*/}
                                            {/*    <p>Fatima Asrafy standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections Bonorum et Malorum original.</p>*/}
                                            {/*</div>*/}
                                            <div className="author-info">
                                                <h6><span>John </span> - Gym</h6>
                                            </div>
                                            <p className="description">
                                                Description.
                                            </p>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            {/*<div className="inner">*/}
                                            {/*    <p>Jannin Jow The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections Bonorum et Malorum original.</p>*/}
                                            {/*</div>*/}
                                            <div className="author-info">
                                                <h6><span>Kelly </span> - Gym</h6>
                                            </div>
                                            <p className="description">
                                                Description.
                                            </p>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            {/*<div className="inner">*/}
                                            {/*    <p>Standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections Bonorum et Malorum original.</p>*/}
                                            {/*</div>*/}
                                            <div className="author-info">
                                                <h6><span>Billy </span> - Sophrologie</h6>
                                            </div>
                                            <p className="description">
                                                Description.
                                            </p>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            {/*<div className="inner">*/}
                                            {/*    <p>John Doe The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections Bonorum et Malorum original.</p>*/}
                                            {/*</div>*/}
                                            <div className="author-info">
                                                <h6><span>Jane </span> - Fitness</h6>
                                            </div>
                                            <p className="description">
                                                Description.
                                            </p>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            {/*<div className="inner">*/}
                                            {/*    <p>Chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections Bonorum et Malorum original.</p>*/}
                                            {/*</div>*/}
                                            <div className="author-info">
                                                <h6><span>Paul </span> - Running</h6>
                                            </div>
                                            <p className="description">
                                                Description.
                                            </p>
                                        </div>
                                    </TabPanel>

                                    {/*<TabPanel>*/}
                                    {/*    <div className="rn-testimonial-content text-left">*/}
                                    {/*        <div className="inner">*/}
                                    {/*            <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections Bonorum et Malorum original.</p>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="author-info">*/}
                                    {/*            <h6><span>Jaffin </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</TabPanel>*/}

                                    {/*<TabPanel>*/}
                                    {/*    <div className="rn-testimonial-content text-left">*/}
                                    {/*        <div className="inner">*/}
                                    {/*            <p>Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections Bonorum et Malorum original.</p>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="author-info">*/}
                                    {/*            <h6><span>JON CUMMINS </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</TabPanel>*/}
                                </div>
                                <div className="col-lg-6 mt_md--40 mt_sm--40">
                                    <TabList className="testimonial-thumb-wrapper">
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-1.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-2.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-3.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-4.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-5.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-6.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        {/*<Tab>*/}
                                        {/*    <div className="testimonial-thumbnai">*/}
                                        {/*        <div className="thumb">*/}
                                        {/*            <img src="/assets/images/client/testimonial-7.jpg" alt="Testimonial Images"/>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</Tab>*/}
                                        {/*<Tab>*/}
                                        {/*    <div className="testimonial-thumbnai">*/}
                                        {/*        <div className="thumb">*/}
                                        {/*            <img src="/assets/images/client/testimonial-8.jpg" alt="Testimonial Images"/>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</Tab>*/}
                                    </TabList>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;