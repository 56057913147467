import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp, FiMail, FiCopy, FiAward, FiUser } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiUsers />,
        title: 'Adhésion Annuelle Famille',
        description: '30 € / famille'
    },
    {
        icon: <FiUser />,
        title: 'Adhésion Annuelle Adulte',
        description: '25 € / personne'
    },
    {
        icon: <FiAward />,
        title: 'Adhésion Annuelle Etudiant / Ado',
        description: '10 € / personne'
    }
];

class ServiceTwo extends Component{
    render(){
        let title = 'Adhésion à l\'association',
        description = 'Rejoignez-nous !<br />ADHERER à l\'association, c\'est avoir la garantie de participer à nos événements et à des tarifs préférentiels !!!',
        subtitle= 'What we can do for you';
        return(
            <React.Fragment>
                <div className="container">
                    <div className="row" data-aos="fade-up">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <h2 className="title">{title}</h2>
                                <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                            </div>
                        </div>
                    </div>

                    <div className="row" data-aos="fade-up" data-aos-delay="100">
                        <div className="col-lg-12 col-12 mt--30">
                            <div className="row service-main-wrapper">
                                {ServiceList.map( (val , i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <a href="/service-details">
                                            <div className="service service__style--2 text-left">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
